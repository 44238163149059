<template>
<Form >
  <Field
    name="lc"
    as="select"
    class="form-control"
    @change="onLocaleChange($event) " v-model="locale"
  >
    <option v-for="(locale, i) in locales" class="form-control" :key="`locale-${i}`" :value="locale" @change="onLocaleChange($event)">
      {{ locale }}
    </option>
  </Field>

  </Form >
</template>

<script>
import { Form, Field } from 'vee-validate'
import { setLocale } from '@vee-validate/i18n';
import store from '@/store'

export default {
  name: "LocaleSwitcher",
  components: {
  Form,
  Field,
 
},
  data() {
    return { locales: ["ua", "en"] };

  },


  methods: {
    // Called when the user selects a new locale
    // from the dropdown
    onLocaleChange(event_) {

      store.dispatch('initialiseStore')
      setLocale(event_.target.value);
      this.locale = event_.target.value
      this.$i18n.locale = event_.target.value
      this.$store.commit('setChosenLocale', event_.target.value)
      store.dispatch('getLang', event_.target.value).then(() => {})
      // Navigate to the localized root route for
      // the chosen locale
      //this.$router.push(`/${newLocale}`)
      this.$router.push(`/`)
    },
  },

  created() {
    
    this.locale = this.$i18n.locale
    
    console.log("LocaleSwitcher", this.locale)
    setLocale(this.locale);
  },
  
};
</script>